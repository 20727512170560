import React from 'react';

import { Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { isNorthAmericanCountry } from '@ha/core/SupportedCountries';
import { Typography, Link, units } from '@hbf/dsl/core';
import { ArrowCounterClockwise } from '@hbf/icons/brand-bold';

import { useIntl } from 'ha/i18n';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { Track } from 'ha/components/track/SmartTrack';
import { MATCHMAKING_FILTERS_QUERY_KEY } from 'ha/modules/FilterBasedMatchmaking/constants';
import { useSavedLocalSearch } from 'ha/modules/SavedSearches/hooks/useSaveLocalSearch';
import { getIsLoading } from 'ha/modules/SavedSearches/reducer';

import { useConstructSearchFilters } from './useConstructSearchFilters';
import { useSearchCityData } from './useSearchCityData';

const useStyles = makeStyles()(theme => ({
  mostRecentSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingInline: units.rem(0.5),
    gap: theme.utils.spacing('ref/spacing/2'),
    [theme.breakpoints.up('md')]: {
      paddingInline: 'unset',
      justifyContent: 'flex-start',
      gap: theme.utils.spacing('ref/spacing/1'),
      color: theme.palette.neutral[100],
    },
  },
  text: {
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/2'),
    [theme.breakpoints.up('md')]: {
      color: theme.palette.neutral[100],
    },
  },

  rentOutLink: {
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontWeight: 'normal',
    },
  },
  continueSearchText: {
    whiteSpace: 'nowrap',
  },

  recentSearchLink: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  arrowCounterClockwise: {
    fontSize: theme.utils.spacing('ref/spacing/4'),
  },
}));

const RentOutButton = () => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();

  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const content = (
    <Typography
      variant="body/sm-regular"
      className={classes.text}
      component="h4"
    >
      {T('home.for_landlord.looking_tenants')}
      <Track name="Clicked List your place on homepage" type="anchor">
        <Link
          href={urlResolver.getPrivateLandlordLandingPageUrl()}
          size="sm"
          underline="always"
          className={classes.rentOutLink}
        >
          {T('home.for_landlord.cta_rent_out')}
        </Link>
      </Track>
    </Typography>
  );
  if (isSmallerThanMd) {
    return (
      <div data-test-locator="HeroSectionFooterMobile/RentOutButton">
        {content}
      </div>
    );
  }
  return content;
};

export const MostRecentSearch = () => {
  const { T, urlResolver, lang } = useIntl();
  const { enableFilterBasedMatchmaking } = useFeatureFlags();

  const { classes, theme } = useStyles();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const isFetchingSavedSearch = useSelector(getIsLoading);
  const latestSavedSearch = useSavedLocalSearch({
    isLoaded: !isFetchingSavedSearch,
  });

  const {
    constructAdvertiserRating,
    constructBillsIncluded,
    constructCategories,
    constructContractType,
    constructDateFilter,
    constructFacilitiesAndAmenities,
    constructFurniture,
    constructOccupationAndGender,
    constructPriceFilter,
    constructRegistration,
    constructSizeFilter,
  } = useConstructSearchFilters(latestSavedSearch?.filters);

  const { searchCityData, isFetching: isFetchingSearchCityData } =
    useSearchCityData({
      city: latestSavedSearch?.city || '',
      countryCode: latestSavedSearch?.countryCode || '',
    });

  if (isFetchingSearchCityData || isFetchingSavedSearch) {
    return <Skeleton width="25%" height={30} />;
  }

  if (!latestSavedSearch || !searchCityData?.[lang]) {
    return <RentOutButton />;
  }

  const latestSavedSearchFilters = {
    ...latestSavedSearch?.filters,
    ...(isEnabled(enableFilterBasedMatchmaking) && {
      [MATCHMAKING_FILTERS_QUERY_KEY]: true,
    }),
  };

  return (
    <div
      className={classes.mostRecentSearch}
      data-test-locator="HomeSection/MostRecentSearch"
    >
      {isLargerThanMd ? (
        <Typography
          variant="body/md-semibold"
          className={classes.continueSearchText}
        >
          {T('search_history.homepage_prompt.known_last_search')}
        </Typography>
      ) : (
        <ArrowCounterClockwise className={classes.arrowCounterClockwise} />
      )}
      <Typography
        variant="body/sm-regular"
        className={classes.recentSearchLink}
      >
        <Track name="Clicked Latest Search and Filters">
          <Link
            href={urlResolver.getSearchUrl(
              searchCityData[lang].cityName,
              searchCityData[lang].countryName,
              latestSavedSearchFilters as Record<string, unknown>,
            )}
            size="sm"
            underline="always"
          >
            {[
              searchCityData[lang].cityName,
              constructDateFilter(),
              constructPriceFilter(),
              constructBillsIncluded(),
              constructCategories(),
              constructAdvertiserRating(),
              constructFacilitiesAndAmenities(),
              constructOccupationAndGender(),
              constructSizeFilter({
                isImperialSystem: isNorthAmericanCountry(
                  searchCityData[lang].countryCode,
                ),
              }),
              constructFurniture(),
              constructContractType(),
              constructRegistration(),
            ]
              .filter(item => item !== '')
              .join(', ')}
          </Link>
        </Track>
      </Typography>
    </div>
  );
};
